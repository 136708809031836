import { Link } from 'gatsby';
import React from 'react';
import iconBadge from '../images/icons/card-badge.svg'
import iconClock from '../images/icons/card-clock.svg'
import iconSearch from '../images/icons/card-search-heart.svg'
import reason1 from '../images/index/reason-1.svg';
import reason2 from '../images/index/reason-2.svg';
import reason3 from '../images/index/reason-3.svg';
import reason4 from '../images/index/reason-4.svg';
import rightCardImage1 from '../images/index/horizontal-step-1.png';
import rightCardImage2 from '../images/index/horizontal-step-2.png';
import rightCardImage3 from '../images/index/horizontal-step-3.png';

export default {
  reasons: [
    {
      title: 'Бърз оптичен интернет, независещ от бурята, енергото и съсед',
      content: 'С колко доставчици на оптичен интернет можеш хем да се носиш свободно по вълната, хем да си напълно сигурен, че няма да паднеш точно в апогея на изживяването? С интернет и телевизия от нас мачът няма да забива, филмът тръгва за миг, работата ти върви по вода. NetSurf те подсигурява да правиш онлайн всичко, което харесваш или за което ти се плаща, без да мислиш за нищо друго. Високоскоростен домашен оптичен интернет със скорост за сваляне до 1000 Mbit/s и непрекъсваема оптична телевизия с GPON технология за шампиони. При това с денонощна връзка с нас. Хубаво е да си кралят!',
      color: '#19DCDE',
      image: reason1,
      bgColor: 'white',
    },
    {
      title: 'Стани властелин на времето (и ефира) с интерактивна телевизия',
      content: 'Денят да си отспиш, спешен повик по телефона или от природата, кучето напира да го изведеш – е, дотук с угризенията! С нашата интерактивна телевизия за първи път печелиш епичния си двубой с времето. NetSurf ти дава шанс да спираш на пауза любимите ти сериали, филми, шоу- и всякакви програми, да ги връщаш на избран момент или да ги записваш. Това обаче са само част от възможностите. А ако ти се гледа цифрова телевизия на различни платформи, възползвай се и от услугата Multiscreen. Истинско завръщане в бъдещето.',
      color: '#9483E8',
      image: reason2,
      bgColor: 'white',
    },
    {
      title: 'Знаем, че си уникален, и ти го доказваме с индивидуална оферта',
      content: 'Неслучайно над 25 години сме тук – защото съзнаваме, че ти имаш свои нужди, желания и виждания за нашия продукт. Сподели с нас и ще ти отвърнем с индивидуална оферта за цифрова телевизия и/или оптичен интернет искрено и лично за теб, с всички допълнителни услуги и 24/7 гаранцията на NetSurf. Знаем, че в работата те гонят срокове и че вкъщи е лудница, затова пет дни ще „забравим” да ти спрем нета, ако ти си забравил, че трябва да платиш. Ако пък ти се наложи да заминеш по задачи или за кеф, ще ти изрежем периода, за да не го плащаш, и ще го добавим начисто, когато се завърнеш.',
      color: '#F4CB62',
      image: reason3,
      bgColor: 'white',
    },
    {
      title: 'Защото лоялността е награда за двама',
      content: 'Ти си лоялен към нас, а ние сме лоялни към теб и няма да те оставим сам, без телевизия и интернет в този глобално обвързан свят. Ако забравиш да си платиш сметката или да си подновиш договора се обади на 0885250000 и ние ще ти удължим услугата за 24 или 48 часа.',
      color: '#F5CED0',
      image: reason4,
      bgColor: 'white',
    },
  ],

  cityCoverage: [
    'Монтана', 'Лом', 'Берковица',
    'Враца', 'Вършец', 'Оряхово',
    'Благоевград', 'Козлодуй', 'и техните региони.'
  ],

  clientOpinions: [
    { name: 'Ангел Т.', date: 'Вършец', opinion: '"Ползвам пакетна услуга и съм изключително доволен от качеството на телевизионната услуга и големия избор от канали. Интернетът е много бърз, сайтовете зареждат моментално."' },
    { name: 'Анелия К.', date: 'Вършец', opinion: '"Много ми харесва, че нямаме антени и сателитни чинии. Всичко е през кабел и сигналът нито се влошава нито прекъсва след първия гръм. Хубаво е да можеш да гледаш телевизия, дори когато навън е буря."' },
    { name: 'Ирина П.', date: 'Берковица', opinion: '"За нас и скоростта на интернета и качеството на телевизята са от значение. Не сме имали сериозни проблеми, но когато се появят кратките прекъсвания веднага ни се обръща внимание и проблемът се отстранява в много кратки срокове. Почти не усещаме прекъсванията."' },
    { name: 'Димитрана П.', date: 'Берковица', opinion: '"Като изявена киноманка, за мен е важно да има разнообразие от филмови канали. NetSurf предлага изобилие от такива канали и съм много доволна. Детето ми главно сърфира в интернет. Качеството на ТВ сигнала и на интернета са изключително високи. Страхотни!"' },
    { name: 'Цветан Х.', date: 'Монтана', opinion: '"Коректна компания, доволен съм от всичко тяхно, което ползвам -- включително и от отношенито на служителите им. Истински професионалисти."' },
    { name: 'Илиян Д.', date: 'Козлодуй', opinion: '"Предлагат специални оферти за лоялни клиенти, по-този начин спестявам за пълния пакет."' },
    { name: 'Милослава П.', date: 'Козлодуй', opinion: '"Качеството на избраната от нас услуга отговаря на цената. Непрекъсваемостта е от значение за избора ни на доставчик."' },
    { name: 'Валерий П.', date: 'Козлодуй', opinion: '"Цялото семейство разчитаме на високата интернет скорост, тъй като обичаме сайтовете да се отварят моментално,а клиповете в YouTube да не прекъсват. Много сме доволни и от обслужването - служителите в магазина са дружелюбни и любезни."' },
    { name: 'Бранимир Ч.', date: 'Оряхово', opinion: '"От както минахме на NetSurf много рядко се случва да се обаждаме за повреди. Доволни сме, че си сменихме доставчика. А пък връщането на предавания назад  много е удобно. Най-накрая и в нашия град някой да направи модерна телевизия. "' },
    { name: 'Юлиан М.', date: 'Оряхово', opinion: '"Избрахме NetSurf, тъй като имат GPON мрежа и това гарантира непрекъсваща връзка с интернет. Така в комбинация и с предлаганата телевизия, в нашите очи сте лидер в индустрията за нашия регион."' },
    { name: 'Миглена Г.', date: 'Лом', opinion: '"Аз съм на принципа колкото повече-толкова повече: искам бърз интернет и много канали. Харесва ми, че някой път като забравя да платя, ми пускат услугата за още един ден. Ей така на доверие."' },
    { name: 'Йото Й.', date: 'Враца', opinion: '"Високите скорости на интернет са ми най-важни. Съпругата пък обича да гледа телевизия с високо качество на картината, без да прекъсва. Плащането през моята банка е много удобно, дори без да ходя в магазин."' },
    { name: 'Живка С.', date: 'Благоевград', opinion: '"Откакто ни прехвърлиха на GPON-а, нямаме вече прекъсвания. Разликата в това отношение е невероятна. Често не хващаме предаванията, които искаме да гледаме, тъй като не сме си вкъщи -- но благодарение на функцията "връщане назад" и този проблем е остранен."' },
    { name: 'Гергана Г.', date: 'Благоевград', opinion: '"За нас е важно да има интернет за всички, тъй като сме 5-членно семейство. Високите скорости и оптичните устройства се справят с тази задача. Заплащането през моето банково мобилно приложение също е от голям плюс и спестява доста време."' },
  ],

  forHomeSection: {
    // text: 'Твоята крепост никога не е била по-забавна и сигурна. Със свръхзвуковия оптичен интернет и пребогатата на съдържание интерактивна телевизия на NetSurf ще можеш да правиш без прекъсвания всичко, което обичаш онлайн, и да не изпускаш нищо любимо на малкия екран.',
    // list: [
    //   'Гъвкави условия по договора според нуждите на всеки клиент',
    //   'Безплатно мобилно приложение за гледане на ТВ и други услуги',
    //   'Пауза, връщане назад, запис и ТВ архив',
    //   'Светкавичен и непрекъсваем GPON интернет до 300 Mbit/s',
    // ],
    linkTo: '/za-doma/',
    linkText: 'Избери пакет',
  },

  forOfficeSection: {
    // text: 'Офисът ти ще работи необезпокояван с непрекъсваем от метеорологичните условия и електрозахранването интернет заради оптичната GPON технология на NetSurf. Корпоративният клиент получава индивидуална оферта и отношение от конкретен наш представител, а неговите проблеми разглеждаме с приоритет и решаваме мигновено с денонощен VIP Support център. Доказано силни сме и в изграждането на вътрешни кабелни мрежи.',
    // list: [
    //   'Сигурен интернет за твоя бизнес',
    //   'Dark Fiber и MAN услуги',
    //   'Изграждане и поддръжка на вътрешна мрежа',
    //   'Персонален акаунт мениджър',
    // ],
    linkTo: '/za-biznesa/',
    linkText: 'Научи повече',
  },

  horizontalCards: [
    {
      title: null,
      icon: iconSearch,
      text: null,
      image: rightCardImage1,
    },
    {
      title: null,
      icon: iconClock,
      text: null,
      image: rightCardImage2,
    },
    {
      title: null,
      icon: iconBadge,
      text: null,
      image: rightCardImage3,
    },
  ]
}
