import '../styles/index.css';

import ClientTestimonials from '../components/index/client-testimonials/client-testimonials';
import Hero from '../components/index/hero/hero';
import Layout from '../components/layout';
import PackageSection from '../components/home/package-section';
import React from 'react';
import Reasons from '../components/shared/reasons';
import SEO from '../components/seo';
import ScrollAnimation from 'react-animate-on-scroll';
import UspHero from '../components/index/usp-hero/usp-hero';
import VideoSection from '../components/index/video';
import constants from '../constants/index';
import { graphql } from 'gatsby';
import meta from '../constants/meta-descriptions';
import schemaJSON from '../constants/schema.json';

const IndexPage = ({ data }) => {
	const testimonials = data.allWordpressWpTestimonials.edges;
	

	return (
		<>
			<Layout activeTab={'/'}>
				<SEO
					title={meta.index.title}
					titleTemplate={`%s`}
					description={meta.index.metaDescription}
					schemaJSON={schemaJSON}
				/>

				<Hero
					title={data.wordpressPage.acf.hero_title}
					text={data.wordpressPage.acf.hero_text}
				/>

				<UspHero />

				<div className="my-5">
					<PackageSection
						currPage="index"
						btnName={data.wordpressPage.acf.package_button_name}
						enableToggle={true}
					/>
				</div>

				<ScrollAnimation animateIn="fadeIn" animateOnce={true}>
					<ClientTestimonials
						sectionTitle={
							<h2>
								Какво мислят
								<br />
								<span className="text-purple">другите за нас</span>
							</h2>
						}
						opinions={testimonials}
					/>
				</ScrollAnimation>

				<ScrollAnimation animateIn="fadeIn" animateOnce={true}>
					<Reasons
						sectionTitle={
							<h2>
								<span className="text-purple">Четири причини</span>
								<br />
								да се качиш на <br />
								NetSurf-а
							</h2>
						}
						reasonsData={constants.reasons}
						textClassName={'text-black'}
					/>
				</ScrollAnimation>

				<ScrollAnimation animateIn="fadeIn" animateOnce={true}>
					<VideoSection
						title={data.wordpressPage.acf.stats_title}
						text={data.wordpressPage.acf.stats_text}
					/>
				</ScrollAnimation>
			</Layout>
		</>
	);
};

export const query = graphql`
	query IndexPageQuery {
		wordpressPage(slug: { eq: "index" }) {
			acf {
				hero_title
				hero_text
				reasons
				stats_title
				stats_text
				coverage_title
				coverage_text
				steps
				za_doma_title
				za_doma_text
				za_biznesa_title
				za_biznesa_text
				second_to_last_section_title
				second_to_last_section_text
				package_button_name
			}
		}
		allWordpressWpTestimonials(
			filter: { acf: { category: { eq: "home" } } }
			sort: { fields: [content] }
		) {
			edges {
				node {
					acf {
						location
						category
						date
					}
					title
					content
				}
			}
		}
		forHomeImage: file(relativePath: { eq: "index/for-home.png" }) {
			childImageSharp {
				fluid(maxHeight: 700, quality: 80) {
					...GatsbyImageSharpFluid_withWebp_noBase64
				}
			}
		}
		forOfficeImage: file(relativePath: { eq: "index/for-office.png" }) {
			childImageSharp {
				fluid(maxHeight: 700) {
					...GatsbyImageSharpFluid_withWebp_noBase64
				}
			}
		}
		mapImagePlaceholder: file(relativePath: { eq: "index/map.png" }) {
			childImageSharp {
				fluid(maxHeight: 1283) {
					...GatsbyImageSharpFluid_withWebp_noBase64
				}
			}
		}
	}
`;

export default IndexPage;

